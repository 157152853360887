<template>
  <div class="home">
    <header>
      <m-page-header title="操作指南"></m-page-header>
    </header>
    <main>
      <div class="description">
        <ul>
          <li class="title">
            <h5>商城后台帮助中心有默认常见条款，本插件可以一键配置更新常见条款内容。</h5>
          </li>
          <li>1.在配置基础信息中根据店铺信息配置正确的内容。</li>
          <li>2.选择需要配置更新的条款，点击同步即可配置更新条款内容。</li>
          <li>3.条款只会同步至帮助中心的二级内容。</li>
        </ul>
      </div>
      <div class="images">
        <m-image-preview :imgList="[homePreview1, homePreview2]" />
      </div>
    </main>
  </div>
</template>

<script>
import MPageHeader from '@/components/pageHeader/PageHeader.vue';
import MImagePreview from '@/components/imagePreview/ImagePreview.vue';

import homePreview1 from '../statics/img/home1.png';
import homePreview2 from '../statics/img/home2.png';
export default {
  name: 'Home',
  components: {
    'm-page-header': MPageHeader,
    'm-image-preview': MImagePreview,
  },
  setup() {
    return {
      homePreview1,
      homePreview2,
    };
  },
};
</script>

<style lang="scss" scoped>
main {
  .description {
    ul {
      padding: 20px;
      li {
        padding: 8px 0;
        .title {
          padding: 10px 0;
        }
      }
    }
  }
  .images {
    padding: 20px;
  }
}
</style>
